import { getCookies } from 'cookies-next'

import { createApiClient } from '../utils/apiClient'
import { isObjectEmpty } from '../utils/Helpers'

// apiClient.interceptors.response.use(
//     (response) => {
//       return response;
//     }, function (error) {
//       let originalRequest = error.config;
//       if (
//           error.response &&
//           error.response.status === 401 &&
//           !originalRequest._retry &&
//           localStorage.getItem('refresh_token') !== undefined
//       ) {
//         originalRequest._retry = true;
//         if (localStorage.getItem('refresh_token') && localStorage.getItem('refresh_token') != 'undefined') {
//           return axios.get(config.pegasusApi + '/refreshToken/' + localStorage.getItem('refresh_token'))
//               .then((response) => {
//                 localStorage.setItem('jwt-token', response.data.jwt_token);
//                 localStorage.setItem('refresh_token', response.data.refresh_token);
//                 store.dispatch('loginSucceeded', {
//                   jwt_token: response.data.jwt_token,
//                   refresh_token: response.data.refresh_token
//                 });
//                 originalRequest.headers.Authorization = 'Bearer ' + localStorage.getItem('jwt-token');
//                 return axios(originalRequest)
//               }).catch((error) => {
//                 return Promise.reject(error)
//               })
//         }
//         return Promise.reject(error);
//       }
//       return Promise.reject(error);
//     });
const apiClient = createApiClient()
apiClient.interceptors.request.use(
  function (request) {
    const token = getCookies('access_token')
    if (token != null) {
      request.headers.Authorization = `Bearer ${token}`
    }
    return request
  },
  function (err) {
    return Promise.reject(err)
  }
)

export default {
  get(resource, id = null, queryParams = null, token = null) {
    const apiClient = createApiClient(token)
    if (id) {
      resource += '/' + id
    }
    resource = encodeURI(resource)
    if (queryParams) {
      resource += '?'
      for (const key in queryParams) {
        resource += key + '=' + encodeURIComponent(queryParams[key])
        delete queryParams[key]
        if (!isObjectEmpty(queryParams)) {
          resource += '&'
        }
      }
    }
    return apiClient.get(resource)
  },
  post(resource, data, token = null) {
    const apiClient = createApiClient(token)
    return apiClient.post(resource, data)
  },
  delete(resoruce, id, token) {
    const url = resoruce + '/' + id
    const apiClient = createApiClient(token)
    return apiClient.delete(url)
  },
  patch(resource, data, token = null) {
    const apiClient = createApiClient(token)
    return apiClient.patch(resource, data)
  },
}
