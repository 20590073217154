import {
  Array,
  Number,
  Record,
  Static,
  String,
  InstanceOf,
  Union,
  Null,
  Partial,
  Literal,
} from 'runtypes'

/* eslint-disable @typescript-eslint/camelcase */
export const Category = Record({
  color: String,
  id: Number,
  name: String,
}).And(
  Partial({
    navbar: Union(Literal(0), Literal(1)),
    subcategories: Array(
      Record({
        color: String,
        id: Number,
        name: String,
      })
    ),
  })
)

export const CategoryWithSortOrder = Category.And(
  Record({
    sort_order: Number,
  })
)

export const Categories = Record({
  categories: Array(Category),
})

export const CategoryApiResponse = Record({
  id: Number,
  name: String,
  navbar: Union(Literal(0), Literal(1)),
  sort_order: Number,
  created_at: Union(InstanceOf(Date), Null),
  updated_at: Union(InstanceOf(Date), Null),
  color: String,
  image: String,
  sport_schedule: Number,
  pivot: Record({
    post_id: Number,
    category_id: Number,
  }),
})

export type CategoryApiResponse = Static<typeof CategoryApiResponse>

export type Category = Static<typeof Category>
export type CategoryWithSortOrder = Static<typeof CategoryWithSortOrder>
export type Categories = Static<typeof Categories>
