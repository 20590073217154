/* eslint-disable @typescript-eslint/camelcase */

import { useMemo } from 'react'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

let store

export const actions = {
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_IS_USER_LOGGED_IN: 'SET_IS_USER_LOGGED_IN',
  SET_USER: 'SET_USER',
  SET_OPENED_POST: 'SET_OPENED_POST',
  SHOW_SIGNUP_MODAL: 'SHOW_SIGNUP_MODAL',
  HIDE_SIGNUP_MODAL: 'HIDE_SIGNUP_MODAL',
  SHOW_VIDEO_MODAL: 'SHOW_VIDEO_MODAL',
  HIDE_VIDEO_MODAL: 'HIDE_VIDEO_MODAL',
  SET_VIDEO: 'SET_VIDEO',
  TOGGLE_SEARCH_BAR: 'TOGGLE_SEARCH_BAR',
  COMMENT_LIKED: 'COMMENT_LIKED',
  COMMENT_DISLIKED: 'COMMENT_DISLIKED',
  COMMENT_ADDED: 'COMMENT_ADDED',
  UPDATE_CATEGORY_PAGE: 'UPDATE_CATEGORY_PAGE',
  UPDATE_SUB_CATEGORY_PAGE: 'UPDATE_SUB_CATEGORY_PAGE',
  UPDATE_COMMENTS_PAGINATION: 'UPDATE_COMMENTS_PAGINATION',
  SET_WEATHER_DATA: 'SET_WEATHER_DATA',
  SET_SEARCH_RESULTS: 'SET_SEARCH_RESULTS',
  SET_SPORT_SCHEDULE: 'SET_SPORT_SCHEDULE',
  TOGGLE_SHOW_SUB_COMMENTS: 'TOGGLE_SHOW_SUB_COMMENTS',
  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
  SET_NEWEST_POSTS: 'SET_NEWEST_POSTS',
  SET_MOST_VIEWED_POSTS: 'SET_MOST_VIEWED_POSTS',
  SET_BANNERS: 'SET_BANNERS',
  TOGGLE_LOGGED_USER_MENU_MODAL: 'TOGGLE_LOGGED_USER_MENU_MODAL',
  TOGGLE_CONFIRM_DELETE_ACCOUNT_MODAL: 'TOGGLE_CONFIRM_DELETE_ACCOUNT_MODAL',
  SET_SHOW_SUB_NAVIGATION_BAR_FOR_CATEGORY:
    'SET_SHOW_SUB_NAVIGATION_BAR_FOR_CATEGORY',
  SET_SELECTED_CATEGORY: 'SET_SELECTED_CATEGORY',
  SET_SELECTED_SUB_CATEGORY: 'SET_SELECTED_SUB_CATEGORY',
  SET_MAIN_PAGE_POSTS: 'SET_MAIN_PAGE_POSTS',
  SET_SHOW_BANNER: 'SET_SHOW_BANNER',
  SET_BANNER_MODAL_HIDDEN: 'SET_BANNER_MODAL_HIDDEN',
}

const initialState = {
  categories: [],
  isUserLoggedIn: false,
  user: null,
  openedPost: null,
  displaySignupModal: false,
  displayVideoModal: false,
  videoUrl: null,
  post: null,
  displaySearchBar: false,
  categoryPage: {},
  commentsPagination: {},
  weatherData: null,
  searchResultsPagination: null,
  emptySearchResults: false,
  sportSchedule: [],
  searchQuery: null,
  newestPosts: [],
  mostViewedPosts: [],
  banners: [],
  showLoggedUserMenuModal: false,
  showConfirmDeleteAccountModal: false,
  showSubNavigationBarForCategory: null,
  selectedCategory: null,
  selectedSubCategory: null,
  mainPagePosts: [],
  mainPagePostsCurrentPage: 1,
  displayCommentsModal: false,
  showBanner: false,
  bannerModalHidden: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload.categories,
      }
    case actions.SET_IS_USER_LOGGED_IN:
      return {
        ...state,
        isUserLoggedIn: action.payload.isUserLoggedIn ?? false,
      }
    case actions.SET_USER:
      return {
        ...state,
        user: action.payload.user,
      }
    case actions.SET_OPENED_POST:
      return {
        ...state,
        openedPost: action.payload.post,
      }
    case actions.SHOW_SIGNUP_MODAL:
      return {
        ...state,
        displaySignupModal: true,
      }
    case actions.HIDE_SIGNUP_MODAL:
      return {
        ...state,
        displaySignupModal: false,
      }
    case actions.SHOW_VIDEO_MODAL:
      return {
        ...state,
        displayVideoModal: true,
      }
    case actions.HIDE_VIDEO_MODAL:
      return {
        ...state,
        displayVideoModal: false,
      }
    case actions.SET_VIDEO:
      if (!action.payload) return
      return {
        ...state,
        videoUrl: action.payload,
      }
    case actions.TOGGLE_SEARCH_BAR:
      return {
        ...state,
        displaySearchBar: !state.displaySearchBar,
        emptySearchResults: false,
        searchResultsPagination: null,
      }
    case actions.COMMENT_LIKED:
      if (state.commentsPagination.comments) {
        const modifiedComments = state.commentsPagination.comments.map(
          (comment) => {
            return {
              ...comment,
              total_likes:
                comment.id === action.payload.commentId
                  ? (comment.total_likes += 1)
                  : comment.total_likes,
              like_dislike:
                comment.id === action.payload.commentId
                  ? { ...comment.like_dislike, likes: 1 }
                  : comment.like_dislike,
              childs: comment.childs.map((comment) => {
                return {
                  ...comment,
                  total_rel_likes_count:
                    comment.id === action.payload.commentId
                      ? (comment.total_rel_likes_count += 1)
                      : comment.total_rel_likes_count,
                  like_dislike:
                    comment.id === action.payload.commentId
                      ? { ...comment.like_dislike, likes: 1 }
                      : comment.like_dislike,
                }
              }),
            }
          }
        )
        return {
          ...state,
          commentsPagination: {
            ...state.commentsPagination,
            comments: modifiedComments,
          },
        }
      }
      return state
    case actions.COMMENT_DISLIKED:
      if (state.commentsPagination.comments) {
        const modifiedComments = state.commentsPagination.comments.map(
          (comment) => {
            return {
              ...comment,
              total_dislike:
                comment.id === action.payload.commentId
                  ? (comment.total_dislike += 1)
                  : comment.total_dislike,
              like_dislike:
                comment.id === action.payload.commentId
                  ? { ...comment.like_dislike, dislikes: 1 }
                  : comment.like_dislike,
              childs: comment.childs.map((comment) => {
                return {
                  ...comment,
                  total_rel_dislikes_count:
                    comment.id === action.payload.commentId
                      ? (comment.total_rel_dislikes_count += 1)
                      : comment.total_rel_dislikes_count,
                  like_dislike:
                    comment.id === action.payload.commentId
                      ? { ...comment.like_dislike, dislikes: 1 }
                      : comment.like_dislike,
                }
              }),
            }
          }
        )
        return {
          ...state,
          commentsPagination: {
            ...state.commentsPagination,
            comments: modifiedComments,
          },
        }
      }
      return state
    case actions.COMMENT_ADDED:
      if (!action.payload.comment.parent_comment_id) {
        return {
          ...state,
          commentsPagination: {
            ...state.commentsPagination,
            comments: [
              action.payload.comment,
              ...state.commentsPagination.comments,
            ],
            total: state.commentsPagination.total + 1,
          },
        }
      } else {
        const modifiedComments = state.commentsPagination.comments
        modifiedComments.map((comment) => {
          if (comment.id === action.payload.comment.parent_comment_id) {
            return {
              ...comment,
              childs: comment.childs.push(action.payload.comment),
            }
          } else {
            return comment
          }
        })
        return {
          ...state,
          commentsPagination: {
            ...state.commentsPagination,
            comments: modifiedComments,
          },
        }
      }
    case actions.UPDATE_CATEGORY_PAGE:
      return {
        ...state,
        categoryPage: {
          ...state.categoryPage,
          posts: [
            ...state.categoryPage.posts,
            ...action.payload.categoryPage.posts,
          ],
          page: action.payload.categoryPage.page,
        },
      }
    case actions.UPDATE_SUB_CATEGORY_PAGE:
      return {
        ...state,
        subCategoryPage: {
          ...state.subCategoryPage,
          posts: [
            ...state.subCategoryPage.posts,
            ...action.payload.subCategoryPage.posts,
          ],
          page: action.payload.subCategoryPage.page,
        },
      }
    case actions.UPDATE_COMMENTS_PAGINATION:
      return {
        ...state,
        commentsPagination: {
          ...state.commentsPagination,
          comments: [
            ...state.commentsPagination.comments,
            ...action.payload.comments,
          ],
          page: action.payload.page,
        },
      }
    case actions.SET_WEATHER_DATA:
      return {
        ...state,
        weatherData: action.payload.data,
      }
    case actions.SET_SEARCH_RESULTS:
      if (action.payload.results) {
        return {
          ...state,
          searchResultsPagination: {
            ...action.payload.results,
            data: [...action.payload.results.data], // add ...results in this array to keep old search results
          },
          emptySearchResults: false,
        }
      }
      return {
        ...state,
        searchResultsPagination: null,
        emptySearchResults: true,
      }

    case actions.SET_SPORT_SCHEDULE:
      if (!action.payload.sportSchedule) {
        return state
      }
      return {
        ...state,
        sportSchedule: [
          ...state.sportSchedule,
          ...action.payload.sportSchedule,
        ],
      }
    case actions.TOGGLE_SHOW_SUB_COMMENTS:
      return {
        ...state,
        commentsPagination: {
          ...state.commentsPagination,
          comments: state.commentsPagination.comments.map((comment) => {
            if (comment.id === action.payload.id) {
              if (
                comment.show_sub_comments === undefined ||
                !comment.show_sub_comments
              ) {
                return {
                  ...comment,
                  show_sub_comments: true,
                }
              }
              return {
                ...comment,
                show_sub_comments: false,
              }
            } else {
              return comment
            }
          }),
        },
      }
    case actions.SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload.query,
      }
    case actions.SET_NEWEST_POSTS:
      return {
        ...state,
        newestPosts: [...state.newestPosts, ...action.payload.newestPosts],
      }
    case actions.SET_MOST_VIEWED_POSTS:
      return {
        ...state,
        mostViewedPosts: [
          ...state.mostViewedPosts,
          ...action.payload.mostViewedPosts,
        ],
      }
    case actions.SET_BANNERS:
      return {
        ...state,
        banners: action.payload.banners,
      }
    case actions.TOGGLE_LOGGED_USER_MENU_MODAL:
      return {
        ...state,
        showLoggedUserMenuModal: !state.showLoggedUserMenuModal,
      }
    case actions.TOGGLE_CONFIRM_DELETE_ACCOUNT_MODAL:
      return {
        ...state,
        showConfirmDeleteAccountModal: !state.showConfirmDeleteAccountModal,
      }
    case actions.SET_SHOW_SUB_NAVIGATION_BAR_FOR_CATEGORY:
      return {
        ...state,
        showSubNavigationBarForCategory: action.payload.category,
      }
    case actions.SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.payload.category,
      }
    case actions.SET_SELECTED_SUB_CATEGORY:
      return {
        ...state,
        selectedSubCategory: action.payload.selectedSubCategory,
      }
    case actions.SET_MAIN_PAGE_POSTS:
      // eslint-disable-next-line no-case-declarations
      const mainPagePosts =
        action.payload.hardReset !== undefined && action.payload.hardReset
          ? action.payload.posts ?? []
          : [...state.mainPagePosts, ...action.payload.posts]

      localStorage.setItem('posts', JSON.stringify(mainPagePosts))
      localStorage.setItem('pageNumber', action.payload.page)

      return {
        ...state,
        mainPagePosts,
        mainPagePostsCurrentPage: action.payload.page,
      }
    case actions.SET_SHOW_BANNER:
      return {
        ...state,
        showBanner: action.payload.showBanner,
      }
    case actions.SET_BANNER_MODAL_HIDDEN:
      return {
        ...state,
        bannerModalHidden: action.payload.bannerModalHidden,
      }
    default:
      return state
  }
}

function initStore(preloadedState = initialState) {
  return createStore(
    reducer,
    preloadedState,
    composeWithDevTools(applyMiddleware())
  )
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState)

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    })
    // Reset the current store
    store = undefined
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store
  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState])
  return store
}
