import ApiService from './api'

export default {
  getNews(page) {
    return ApiService.get(`/posts?page=${page}`, null, null)
  },

  getBySlug(slug: string, isPreview = false) {
    let url = `/posts/${slug}`
    if (isPreview) {
      url += '?preview=true'
    }
    return ApiService.get(url, null, null)
  },

  getNewsByCategoryId(id, page = 1, perPage = 20) {
    return ApiService.get(`/posts/category`, id, {
      page,
      perPage,
    })
  },

  searchByQuery(query, page = 1, perPage = 20) {
    return ApiService.get(
      `/posts/search?text=${query}&page=${page}&perPage=${perPage}`,
      null,
      null
    )
  },

  searchByTags(tags: string, page = 1, perPage = 5, excludePostIds = []) {
    const url = `/posts/search`
    const queryParams = {
      page,
      perPage,
    }
    if (tags.length) {
      tags
        .split(',')
        .map(
          (tag: string, index: number) =>
            (queryParams[`tags[${index}]`] = tag.trim())
        )
    }
    if (excludePostIds.length) {
      excludePostIds.map(
        (postId: number, index: number) =>
          (queryParams[`excludeId[${index}]`] = postId)
      )
    }
    return ApiService.get(url, null, queryParams)
  },

  searchByCategoryIds(
    categoryIds: number[],
    page = 1,
    perPage = 5,
    excludePostIds = []
  ) {
    const url = `/posts/search`
    const queryParams = {
      page,
      perPage,
    }
    if (categoryIds.length) {
      categoryIds.map(
        (categoryId: number, index: number) =>
          (queryParams[`categoryId[${index}]`] = categoryId)
      )
    }
    if (excludePostIds.length) {
      excludePostIds.map(
        (postId: number, index: number) =>
          (queryParams[`excludeId[${index}]`] = postId)
      )
    }
    return ApiService.get(url, null, queryParams)
  },

  getSportSchedule(page = 1) {
    return ApiService.get(`/sport-schedule?page=${page}&perPage=15`)
  },

  getNewestPosts(page = 1) {
    return ApiService.get(`/posts/newest?page=${page}&perPage=15`)
  },

  getMostViewedPosts(page = 1) {
    return ApiService.get(`/posts/mostOpened?page=${page}&perPage=5`)
  },
}
