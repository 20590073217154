/* eslint-disable @typescript-eslint/camelcase */
import {
  Boolean,
  InstanceOf,
  Null,
  Number,
  Partial,
  Record,
  Static,
  String,
  Union,
  Array,
} from 'runtypes'

export type News = {
  title: any
  subtitle: string
  url: string
  slug: string
  category: string
}

export const Pivot = Record({
  category_id: Number,
  post_id: Number,
})

export const Post = Record({
  title: String,
  subtitle: String,
  url: String,
  slug: String,
}).And(
  Partial({
    id: Number,
    name: String,
    categoryId: Number,
    publishedAt: Union(InstanceOf(Date), Null),
    featured_in_post: Number,
    chiron_url: Union(Null, String),
    chiron_name: Union(Null, String),
    photo_gallery: Union(Array(String), Null),
    isNew: Boolean,
    hasVideo: Boolean,
    created_at: Union(String, Null),
    updated_at: Union(String, Null),
  })
)

export const PostFromServer = Record({
  author: String,
  co_author: Union(String, Null),
  id: Number,
  name: String,
  content: String,
  subtitle: String,
  slug: String,
  featured_image: String,
  featured_legend: Union(String, Null),
  published_at: Union(InstanceOf(Date), Null),
  tags: String,
  chiron_url: Union(String, Null),
  chiron_name: Union(String, Null),
  photo_gallery: Union(Array(String), Null),
  is_new: Number,
  is_external_source: Union(Number, Null),
  news_source: Union(String, Null),
  category_primary_position: Union(Null, Number),
  created_at: Union(String, Null),
  updated_at: Union(String, Null),
})

export type Post = Static<typeof Post>
export type PostFromServer = Static<typeof PostFromServer>
