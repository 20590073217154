import axios from 'axios'
import { getCookies } from 'cookies-next'
import { Partial, Record, Static, String } from 'runtypes'

import config from '../config.json'

const Headers = Record({
  Accept: String,
  'Content-Type': String,
}).And(
  Partial({
    Authorization: String,
  })
)

type Headers = Static<typeof Headers>

export const createApiClient = (token = null) => {
  const headers: Headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  } else {
    const tokenFromCookie = getCookies('access_token')
    if (tokenFromCookie?.access_token) {
      headers.Authorization = `Bearer ${tokenFromCookie.access_token}`
    }
  }

  return axios.create({
    baseURL: config.apiBaseUrl,
    withCredentials: false,
    headers,
    timeout: 10000,
  })
}
