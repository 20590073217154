import {
  Array,
  Boolean,
  Number,
  Record,
  Static,
  String,
  Undefined,
  Partial,
  Union,
} from 'runtypes'

export const User = Record({
  id: Number,
  name: String,
})

/* eslint-disable @typescript-eslint/camelcase */
export const Comment = Record({
  comment: String,
  id: Number,
  parent_comment_id: Number,
  post_id: Number,
  liked: Boolean,
  disliked: Boolean,
  total_likes: Number,
  total_rel_likes_count: Union(Number, Undefined),
  total_dislike: Number,
  total_rel_dislikes_count: Union(Number, Undefined),
  author: String,
}).And(
  Partial({
    show_sub_comments: Boolean,
    like_dislike: Record({
      id: Number,
      dislikes: Number,
      likes: Number,
      user_comment_id: Number,
    }),
    created_at: String,
  })
)

export const CommentWithChildren = Comment.And(
  Record({
    childs: Array(Comment),
  })
)

export type Comment = Static<typeof Comment>
export type CommentWithChildren = Static<typeof CommentWithChildren>

export const CommentsPagination = Record({
  comments: Array(CommentWithChildren),
  page: Number,
  total: Number,
})

export type CommentsPagination = Static<typeof CommentsPagination>
